import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          d="m 61.5,23.224647 c -9.495573,-0.428058 -19.026528,-0.844335 -28.521172,-0.233415 -5.17764,0.480268 -10.789175,1.074099 -15.010806,4.39449 -2.300538,2.117735 -1.582187,5.558975 0.0027,7.865571 2.365311,3.899341 5.855221,6.961147 8.241757,10.851406 1.310042,0.99909 0.469048,-2.541099 0.286745,-3.333927 -1.356715,-4.620853 -5.920473,-7.258785 -7.752892,-11.597305 -1.03658,-2.212556 0.84458,-4.254845 2.94333,-4.671437 4.622856,-1.007571 9.409987,-0.929394 14.120462,-1.046772 5.988052,-0.0389 11.972475,0.211052 17.955539,0.420113 -4.329609,3.23343 -6.336473,8.555224 -7.494539,13.648693 0.15015,2.088028 -2.30555,1.250989 -3.622803,1.396064 -1.48572,0 -2.971439,0 -4.457157,0 -1.372835,2.476709 2.987983,0.757074 4.20956,1.101207 1.769715,-0.618853 4.180542,0.306842 2.682891,2.390853 -1.342489,6.18197 -2.142253,12.510585 -4.145987,18.527883 -1.585796,2.950889 -5.343053,2.057312 -7.953252,1.327513 -3.774605,-0.798795 -8.071185,-0.694137 -11.255343,1.731467 1.531629,0.56733 4.096451,-0.0091 5.990294,0.478587 3.527856,0.265874 7.268104,1.656869 10.689978,0.152743 3.526036,-1.514398 4.839435,-5.49623 6.223291,-8.756404 1.852447,-5.179555 3.076965,-10.5543 4.36536,-15.894361 2.048346,0 4.096693,0 6.14504,0 1.410504,-1.540943 -1.091144,-0.972282 -1.986187,-1.059488 -1.350968,0 -2.701936,0 -4.052905,0 1.154632,-4.213495 1.220592,-8.896961 3.736247,-12.616297 1.986938,-2.556789 5.396308,-3.006435 8.032628,-4.554667 l 0.318924,-0.255052 0.308232,-0.267465 z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
